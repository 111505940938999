<template>
  <div class="login">
    <div class="left-part">
      <img
        alt="Login image"
        src="@/assets/login/login.jpg"
        srcset="
          @/assets/login/login@2x.jpg 2x
          @/assets/login/login@3x.jpg 3x
        "
        class="login-image"
      >
      <img
        alt="PWC logo"
        :src="pwcLogo"
        class="logo"
      >
      <h4>
        {{ $t('login.welcome') }}<br>
        {{ $t('login.welcome2') }}
      </h4>
    </div>
    <login-form
      :is-error="errorResponse"
      @submit="login"
    />
  </div>
  <!-- <v-row class="text-center">
    <v-col cols="12">
      <h1>{{ $t('login.title') }}</h1>
      <v-spacer />
      <login-form @submit="login" />
      <v-row
        align="center"
        justify="center"
      >
        <v-col sm="3">
          <v-alert
            v-if="error"
            max-width="400"
            type="error"
            dismissible
          >
            {{ $t('login.tryagain') }}
          </v-alert>
          <v-alert
            v-if="success"
            max-width="400"
            type="success"
            dismissible
          >
            {{ $t('login.welldone') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-row> -->
</template>

<script>
import pwcLogo from '@/assets/pwc_outline_logo.svg'
const LoginForm = () => import('./LoginForm')

export default {
  name: 'LoginComponent',
  components: {
    LoginForm
  },
  data () {
    return {
      pwcLogo,
      success: false,
      error: false,
      errorResponse: {}
    }
  },
  methods: {
    async login (sendData) {
      this.success = false
      this.error = false
      try {
        const { data } = await this.$axios.post('/session/login?select=accessToken,user', sendData)
        this.success = true
        this.$store.commit('Token/ADD_ACCESS_TOKEN', data.accessToken)
        this.$store.commit('User/CHANGE_USER_DATA', data.user)
        // this.$router.push({ name: 'QuickStartApp' })
        this.$router.push({ name: 'Dashboard' }).catch(() => true)
      } catch (err) {
        this.error = true
        this.errorResponse = err.response
        console.log(err.response)
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.login {
  display: flex;
  width: 100%;
  height: 100%;

  div.left-part {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    // width: 100%;
    max-width: 598px;

    img.login-image {
      width: auto;
      height: auto;
      max-width: 598px;
      max-height: 100vh;
      // min-height: 100%;
      // max-height: 100vh;
      // max-width: 598px;
      // width: 100%;
      // height: auto;
    }

    img.login-image-mobile {
      display: none;
    }

    img.logo {
      position: absolute;
      top: 53px;
      left: 60px;
    }

    h4 {
      display: none;
    }
  }
}

@media screen and (max-width: 1100px) {
  div.login {
    div.left-part {
      img.login-image {
        margin-left: -111px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.login {
    flex-direction: column;

    div.left-part {
      width: 100%;
      max-width: 100%;
      align-items: flex-start;
      overflow: hidden;
      max-height: 264px;
      background-image: url('../../assets/login/login.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 15%;
      background-color: $primary-black;

      img.login-image {
        display: none;
      }

      img.logo {
        width: 53.13px;
        height: 40px;
        top: 48px;
        left: 24px;
      }

      h4 {
        display: block;
        color: $primary-white;
        position: absolute;
        bottom: 48px;
        left: 50%;
        margin-left: -163.5px; // half of the width of the element
        width: 327px;
      }
    }
  }
}
</style>
